import { useRef, useMemo } from "react"
import tw from "twin.macro"
import Head from "next/head"
import Slider from "react-slick"
import styled from "styled-components"
import dayjs from "dayjs"
import isBetween from "dayjs/plugin/isBetween"

import { SeasonalCalendarProps } from "components/dynamic"

import { colors, fontBase, breakpoint } from "styles/Variables"

import Grid from "components/Grid"
import SRText from "components/SRText"
import Container, { CONTAINER_SIZE } from "components/Container"
import { SliderButton } from "components/Carousels/Gallery"
import { ImageTilesGrid } from "components/TileGrid/styles"

import ArrowRight from "icons/ArrowRight"
import ArrowLeft from "icons/ArrowLeft"

import Slide from "./Slide"

dayjs.extend(isBetween)

// TODO: Clip-path won't scale up perfectly if there are a lot of "see and do" items
export const SeasonalSlider = styled(Slider)`
  background-color: ${colors["deep-blue"]};
  clip-path: circle(1420px at 50% 1420px);
  margin-top: 44px;
  ${breakpoint.md`
    clip-path: circle(2500px at 50% 2500px);
    margin-top: 60px;
  `}
  .slick-slide {
    width: ${(CONTAINER_SIZE - 60) / fontBase}rem;
    max-width: calc(100vw - 40px);
    ${ImageTilesGrid}, ${Grid} {
      opacity: 0;
      transition: opacity 0.5s;
    }
    &.slick-active {
      ${ImageTilesGrid}, ${Grid} {
        opacity: 1;
      }
    }
  }
`

export const SeasonalCalendar = ({ seasons = [] }: SeasonalCalendarProps) => {
  const slider = useRef(null)
  const currentSeasonIdx = useMemo(() => {
    const thisYear = dayjs().year()
    return (
      seasons?.findIndex((season) => {
        const startDate = dayjs(season.StartDate)
        const endDate = dayjs(season.EndDate)
        const spansYear = startDate.year() < endDate.year()
        return dayjs().isBetween(
          startDate.year(spansYear ? thisYear - 1 : thisYear),
          endDate.year(thisYear),
          "day"
        )
      }) || 0
    )
  }, [seasons])
  return (
    <>
      <Head>
        <link href="/assets/slick.css" rel="stylesheet" key="slick-css" />
      </Head>
      <Container tw="relative">
        <div tw="absolute top-48 left-6 text-primary z-10 xl:(top-80 -left-24)">
          <SliderButton
            type="button"
            onClick={() => slider.current.slickPrev()}
          >
            <SRText>Previous season</SRText>
            <ArrowLeft />
          </SliderButton>
        </div>
        <div tw="absolute top-48 right-6 text-primary z-10 xl:(-right-24 top-80)">
          <SliderButton
            type="button"
            onClick={() => slider.current.slickNext()}
          >
            <SRText>Next season</SRText>
            <ArrowRight />
          </SliderButton>
        </div>
      </Container>
      <SeasonalSlider
        variableWidth
        arrows={false}
        centerMode
        controls={false}
        infinite
        adaptiveHeight
        dots={false}
        ref={slider}
        initialSlide={currentSeasonIdx}
      >
        {seasons.map((season, idx) => (
          <Slide
            key={season.id}
            {...season}
            active={idx === currentSeasonIdx}
          />
        ))}
      </SeasonalSlider>
    </>
  )
}

export default SeasonalCalendar
