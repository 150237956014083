const ArrowLeft = (props) => (
  <svg
    width="15"
    height="16"
    viewBox="0 0 15 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M15 8L1 8" stroke="currentColor" />
    <path d="M8 15L1 8L8 1" stroke="currentColor" />
  </svg>
)

export default ArrowLeft
