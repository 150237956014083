import { useRef } from "react"
import tw, { styled } from "twin.macro"
import Slider from "react-slick"
import Head from "next/head"

import Container from "components/Container"

import ArrowRight from "icons/ArrowRight"
import ArrowLeft from "icons/ArrowLeft"

interface GalleryProps {
  galleryData: { id: string | number; alternativeText: string; url: string }[]
}

const GalleryStyled = styled.div`
  z-index: 1;
  width: 100%;
  height: 100%;
  max-width: 85%;
  margin: 0 auto;

  .slick-arrow {
    display: none;
  }
  .slick-prev,
  .slick-next {
    display: none;
  }

  .slick-prev::before {
    display: none;
  }

  .slick-next::before {
    display: none;
  }

  .slick-slide img {
    height: 100%;
    max-height: 90vh;
    object-position: center;
    object-fit: contain;
  }

  .slick-track {
    display: flex;
    align-items: center;
  }
`

export const SliderButton = tw.button`
  cursor-pointer
  w-12.5 h-12.5
  bg-white rounded-full shadow-2xl

  md:(w-20 h-20)
`

const Gallery = ({ galleryData }: GalleryProps) => {
  const slider = useRef(null)

  return (
    <>
      <Head>
        <link href="/assets/slick.css" rel="stylesheet" key="slick-css" />
      </Head>
      <Container tw="z-10 relative pointer-events-none">
        <GalleryStyled>
          <Slider
            tw="pointer-events-auto"
            ref={slider}
            settings={{
              infinite: true,
              slidesToShow: 1,
              slidesToScroll: 1,
              controls: false,
              arrows: false,
              prevArrow: false,
              nextArrow: false,
            }}
          >
            {galleryData.map(({ id, url, alternativeText }) => (
              <img key={id} src={url} alt={alternativeText} />
            ))}
          </Slider>
          <div tw="text-black w-full flex justify-between absolute left-0 top-1/2 transform -translate-y-1/2 text-primary">
            <SliderButton
              tw="lg:-ml-10 pointer-events-auto"
              type="button"
              aria-label="previous"
              onClick={() => slider.current.slickPrev()}
            >
              <ArrowLeft />
            </SliderButton>

            <SliderButton
              tw="lg:-mr-10 pointer-events-auto"
              type="button"
              aria-label="next"
              onClick={() => slider.current.slickNext()}
            >
              <ArrowRight />
            </SliderButton>
          </div>
        </GalleryStyled>
      </Container>
    </>
  )
}

export default Gallery
