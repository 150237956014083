const ArrowRight = (props) => (
  <svg
    width="15"
    height="16"
    viewBox="0 0 15 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M0 8L14 8" stroke="currentColor" />
    <path d="M7 1L14 8L7 15" stroke="currentColor" />
  </svg>
)

export default ArrowRight
