import tw from "twin.macro"
// import Link from "next/link"

import useLFL from "lib/useLFL"
import Image from "components/Image"

import { BodyCopy, H1, H3, H6, IntroBody } from "styles/Text"

import Language from "components/Language"
import Grid from "components/Grid"
import Column from "components/Column"
import TileGrid, { TileTypes } from "components/TileGrid"

// import SecondaryButton from "components/Buttons/Secondary"

const SlideBody = tw.div`
  bg-deep-blue text-white pb-32
`

function tileTypeByComponent(__typename) {
  switch (__typename) {
    case "ComponentRelationExperience":
      return TileTypes.EXPERIENCE
    case "ComponentRelationGeneric":
      return TileTypes.GENERIC
    case "ComponentRelationStory":
      return TileTypes.STORY
    default:
      return TileTypes.GENERIC
  }
}
export const Slide = ({
  Name = "",
  Timing = "",
  Description = "",
  BackgroundImage = null,
  SeeAndDo = [],
  LFLNameID = "",
  active,
}) => {
  const { replacedText } = useLFL(LFLNameID)
  return (
    <SlideBody>
      <div tw="text-center relative pt-24 xl:pt-80">
        <Grid halign="center" tw="relative z-10">
          <Column size={{ lg: 1 / 2 }}>
            {active && (
              <div tw="h-7 w-40 bg-white bg-opacity-20 rounded mx-auto flex items-center justify-center mb-3 -mt-10">
                <H6 as="span">Current Season</H6>
              </div>
            )}
            {Name && <H1 as="h2">{replacedText || Name}</H1>}
            {Timing && <IntroBody>{Timing}</IntroBody>}
            {Description && (
              <BodyCopy tw="mt-20 lg:mt-10">
                <Language>{Description}</Language>
              </BodyCopy>
            )}
            {SeeAndDo?.length > 0 && (
              <H3 tw="mt-24">What you&apos;ll see and do</H3>
            )}
          </Column>
        </Grid>
        {BackgroundImage?.url && (
          <>
            <Image
              layout="fill"
              sizes="(min-width: 1200px) 1200px, 100vw"
              objectFit="cover"
              src={BackgroundImage.url}
              alt={BackgroundImage.alternativeText}
            />
            <div tw="absolute inset-0 bg-black bg-opacity-30" />
            <div tw="absolute inset-0 top-80 h-80 bg-gradient-to-t from-deep-blue" />
            <div tw="absolute inset-0 top-80 mt-80 bg-deep-blue" />
          </>
        )}
      </div>
      <TileGrid
        tw="mt-15 mb-7.5"
        tiles={SeeAndDo.map(({ __typename, tile, ...props }) => ({
          ...props,
          ...tile,
          type: tileTypeByComponent(__typename),
        }))}
      />
      {/* <div tw="text-center relative z-10">
        <Link href="/things-to-do" passHref>
          <SecondaryButton as="a">Explore all things to do</SecondaryButton>
        </Link>
      </div> */}
    </SlideBody>
  )
}

export default Slide
